import { Form, Select, Row, Col, Input } from "antd";
import React, { useEffect, useState } from "react";
import API from "../../../utils/API/API";
const { Option } = Select;
export const SaleRepDropdown = (props) => {
    const { user } = props;
    const [loading, setLoading] = useState(true);
    const [allEmployees, setAllEmployees] = useState([]);
    useEffect(() => {
        API.get("/api/admin/get-all-employees")
            .then((res) => {
                setLoading(false);
                setAllEmployees(res.data.allEmployees);
            })
            .catch((error) => {
                setLoading(false);
            });
    }, []);

    const onChangeSaleRep = (value) => {
        const { formRef } = props;
        const selectEmployee = allEmployees.filter(
            (employee) => employee.email === value
        )[0];
        formRef.current.setFieldsValue({
            createdBy: selectEmployee.name,
            saleRepEmail: selectEmployee.email,
            saleRepPhone: selectEmployee.phone,
        });
    };

    return (
        <Row justify="space-between" align="stretch" style={{ width: "100%" }}>
            {" "}
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                <Form.Item label="Select Sales Rep" name="createdBy">
                    <Select
                        placeholder="Select sales rep"
                        allowClear
                        optionLabelProp="label"
                        loading={loading}
                        onChange={(value) => onChangeSaleRep(value)}
                        disabled={!["admin", "developer"].includes(user.role)}
                    >
                        {allEmployees.map((employee) => {
                            return (
                                <Option
                                    key={employee._id}
                                    value={employee.email}
                                    label={employee.name}
                                >
                                    <div className="demo-option-label-item">
                                        {employee.name}{" "}
                                        <span>({employee.email})</span>
                                    </div>
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                <Form.Item
                    label="Sale Rep Email"
                    name="saleRepEmail"

                >
                    <Input disabled />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                <Form.Item
                    label="Sale Rep Phone"
                    name="saleRepPhone"

                >
                    <Input disabled />
                </Form.Item>
            </Col>
        </Row>
    );
};
